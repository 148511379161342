<template>
  <base-detail-view
    :loading="loading"
    title="Шаблон показателя оценки качества"
    @onRefresh="init"
    :showSave="false"
  >
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              :value="dataSource.Name"
              label="Название"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :value="dataSource.CategoryName"
              label="Раздел деятельности"
              readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  :value="dataSource.RefValue1"
                  type="Number"
                  label="Реф. значение 1"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :value="dataSource.RefValue2"
                  type="Number"
                  label="Реф. значение 2"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :value="dataSource.RefValue3"
                  type="Number"
                  label="Реф. значение 3"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              :value="dataSource.Description"
              label="Описание"
              rows="3"
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <base-list-view
      title="Чек-листы"
      :dataSource="dataSource.CheckLists"
      :headers="checkListsHeaders"
      header-hidden
      isNested
      hide-default-footer
      notShowSelect
      class="mt-2"
    >
    </base-list-view>
  </base-detail-view>
</template>

<script>
import AuditIndicatorService from "../../services/AuditIndicatorService";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseListView from "../../layouts/BaseListView.vue";

export default {
  name: "view-AuditIndicatorTemplateView",
  components: { BaseDetailView, BaseListView },
  mixins: [CreateAndEditMixin],
  data: () => {
    return {
      loading: true,
      apiService: AuditIndicatorService,
      getObjectText: (obj) => obj.Name,
      dataSource: {
        CheckLists: [],
      },
      checkListsHeaders: [
        { text: "Чек-лист", value: "CheckList.Name" },
        { text: "Минимальное количество объектов", value: "ObjectCount" },
      ],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      data.CategoryName = data.ActivitySection?.Name;
      return data;
    },
  },
};
</script>
